import { render, staticRenderFns } from "./index.vue?vue&type=template&id=d6063306&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=d6063306&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d6063306",
  null
  
)

/* custom blocks */
import block0 from "./index.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseUiModalPopup: require('/var/www/homolog.intelli.com.br/intelli.owinteractive.com/components/base/ui/ModalPopup.vue').default,BaseUiBanners: require('/var/www/homolog.intelli.com.br/intelli.owinteractive.com/components/base/ui/Banners.vue').default,BaseUiProductsHighlightSlider: require('/var/www/homolog.intelli.com.br/intelli.owinteractive.com/components/base/ui/ProductsHighlightSlider.vue').default,BaseUiCardSolution: require('/var/www/homolog.intelli.com.br/intelli.owinteractive.com/components/base/ui/CardSolution.vue').default,BaseUiSlideIt: require('/var/www/homolog.intelli.com.br/intelli.owinteractive.com/components/base/ui/SlideIt.vue').default,BaseUiCardProduct: require('/var/www/homolog.intelli.com.br/intelli.owinteractive.com/components/base/ui/CardProduct.vue').default,BaseUiCardNews: require('/var/www/homolog.intelli.com.br/intelli.owinteractive.com/components/base/ui/CardNews.vue').default,BaseUiNewsletter: require('/var/www/homolog.intelli.com.br/intelli.owinteractive.com/components/base/ui/Newsletter.vue').default})
